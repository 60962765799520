.confirm-modal {
    height: 412px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    background-color: white;
}

.agreements-modal {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    background-color: white;
    margin: 10vh 10vw;
    overflow-y: auto;
    max-height: 80vh;
}

.workspace-user-modal {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    background-color: white;
    height: -webkit-fill-available;
    height: -moz-available;
}

.new-workspace-modal {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    background-color: white;
}

.workspace-user-input {
    margin-top: 20px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    width: -webkit-fill-available;
    width: -moz-available;
}

.modal-title {
    height: 64px;
    background-color: $smartester-light;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    padding-left: 20px;
    padding-right: 8px;
    border-radius: 8px 8px 0 0;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
}

.pta-modal{
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
    border-radius: 8px;
    background-color: white;
    width: 700px;
    margin: 100px auto;
}

.saml-modal{
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
    border-radius: 8px;
    background-color: white;
    max-width: 575px;
    font-family: Montserrat-Regular;
    color: $smartester-secondary;
    margin: 30vh auto;

    @media (max-width: 800px) {
        margin: 30vh 10vw;
    }

    h3 {
        align-self: center;
        font-size: 20px;
        font-family: Montserrat-SemiBold;
        margin-top: 10px;
    }

    .saml-modal-content {
        padding: 20px;
    }

    .saml-cancel {
        width: 128px;
        border-radius: 12px;
    }

    .saml-support-cancel-button {
        margin-top: 15px;
    }
}
